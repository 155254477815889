import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import axios from 'axios';

import './scss/content.scss';

import Timeline from '../sections/timeline';
import Comments from '../sections/comments';
import Rewards from '../sections/rewards';
import Guide from '../sections/guide';
import Terms from '../sections/terms';

const dates = {
    'event': {
        'start': '2021-11-10 00:00:00+08:00',
        'end': '2022-01-02 01:30:00+08:00'
    },
    'redemption': {
        'section_1': {
            'start': '2021-11-11 00:00:00+08:00',
            'end': '2021-11-12 01:00:00+08:00'
        },
        'section_2': {
            'start': '2021-11-18 00:00:00+08:00',
            'end': '2021-11-19 01:00:00+08:00'
        },
        'section_3': {
            'start': '2021-12-12 00:00:00+08:00',
            'end': '2021-12-13 01:00:00+08:00'
        },
        'section_4': {
            'start': '2021-12-31 23:00:00+08:00',
            'end': '2022-01-01 01:30:00+08:00'
        }
    },
    'comments': [
        '2021-11-17 11:00:00+08:00',
        '2021-11-24 11:00:00+08:00',
        '2021-12-01 11:00:00+08:00',
        '2021-12-08 11:00:00+08:00',
        '2021-12-15 11:00:00+08:00',
        '2021-12-22 11:00:00+08:00',
        '2021-12-29 11:00:00+08:00',
    ]
};

const Content = (props) => {
    const { uuid, isChina, config, accountInfo, language, triggerPopup } = props;
    const [luckyDraw, setLuckyDraw] = useState('');
    const [timeOffset, setTimeOffset] = useState(0);
    const [loginState, setLoginState] = useState(false);
    const [refreshState, setRefreshState] = useState(false);
    const [eventStatus, setEventStatus] = useState({
        event_1: 'soon',
        event_2: 'soon',
        event_3: 'soon',
        event_4: 'soon'
    });
    const [eligibilityCheck, setEligibilityCheck] = useState(false);
    const [ongoingEvent, setOngoingEvent] = useState(null);
    const [commentCount, setCommentCount] = useState(1000);
    const [winnersList, setWinnersList] = useState([]);
    const { t } = useTranslation();
    let intervalRef = useRef(null);
    let commentCountRef = useRef(null);

    useEffect(() => {
        const getCommentCount = async() => {
            const comment = await axios({
                url: '/api/v1/stalagmite/comments',
                method: 'GET'
            });
            if (comment.data.count) {
                setCommentCount(comment.data.count);
            }
        };

        const getWinnersList = async() => {
            const winners = await axios({
                url: '/api/v1/eventListWinners/getList',
                method: 'GET'
            });
            if (winners.data) {
                setWinnersList(winners.data.winners);
            }
        };

        getTimeOffset();
        getCommentCount();
        getWinnersList();

        commentCountRef.current = setInterval(() => {
            getCommentCount();
        }, 1800000);
        return () => clearInterval(commentCountRef.current);
    }, []);

    useEffect(() => {
        if (uuid && !loginState) {
            setLoginState(true);
        }

        intervalRef.current = setInterval(() => {
            scheduleCheck();
            checkCookie();
        }, 1000);
        return () => clearInterval(intervalRef.current);
    }, [uuid, winnersList]);

    useEffect(() => {
        if (!eligibilityCheck && ongoingEvent) {
            checkEligibility(ongoingEvent);
        }
    }, [eligibilityCheck, ongoingEvent]);

    const getServerTime = () => {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open('HEAD',window.location.origin.toString(),false);
        xmlHttp.setRequestHeader("Content-Type", "text/html");
        xmlHttp.send('');
        return xmlHttp.getResponseHeader("Date");
    };

    const getTimeOffset = () => {
        const serverTime = moment(new Date(getServerTime()));
        setTimeOffset(serverTime.diff(moment()));
    };

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    const scheduleCheck = () => {
        const now = moment(moment().add(timeOffset)).utc();

        for (let i = 0; i < dates.comments.length; i++) {
            if (now.isBefore(dates.comments[i])) {
                setLuckyDraw(winnersList[i] === t('comments.scroller.before') ? t('comments.scroller.before') : t('comments.scroller.ongoing.' + [i-1]) + winnersList[i]);
                break;
            } else if (now.isBetween(dates.comments[i], dates.comments[i+1], undefined, '[)')) {
                setLuckyDraw(t('comments.scroller.ongoing.' + [i]) + winnersList[i+1]);
            }
        }

        if (now.isBefore(dates.event.start)) {
            setEventStatus({
                event_1: 'soon',
                event_2: 'soon',
                event_3: 'soon',
                event_4: 'soon'
            });
        } else {
            if (now.isBefore(dates.redemption.section_1.start)) {
                setLuckyDraw(t('comments.scroller.before'));
                setEventStatus({
                    event_1: 'soon',
                    event_2: 'soon',
                    event_3: 'soon',
                    event_4: 'soon'
                });
            } else if (now.isBetween(dates.redemption.section_1.start, dates.redemption.section_1.end, undefined, '[)')) {
                setOngoingEvent("double-11");
                setEventStatus({
                    event_1: 'ongoing',
                    event_2: 'soon',
                    event_3: 'soon',
                    event_4: 'soon'
                });
            } else if (now.isBetween(dates.redemption.section_1.end, dates.redemption.section_2.start, undefined, '[)')) {
                setOngoingEvent(null);
                setEligibilityCheck(false);
                setEventStatus({
                    event_1: 'ended',
                    event_2: 'soon',
                    event_3: 'soon',
                    event_4: 'soon'
                });
            } else if (now.isBetween(dates.redemption.section_2.start, dates.redemption.section_2.end, undefined, '[)')) {
                setOngoingEvent("anniversary");
                setEventStatus({
                    event_1: 'ended',
                    event_2: 'ongoing',
                    event_3: 'soon',
                    event_4: 'soon'
                });
            } else if (now.isBetween(dates.redemption.section_2.end, dates.redemption.section_3.start, undefined, '[)')) {
                setOngoingEvent(null);
                setEligibilityCheck(false);
                setEventStatus({
                    event_1: 'ended',
                    event_2: 'ended',
                    event_3: 'soon',
                    event_4: 'soon'
                });
            } else if (now.isBetween(dates.redemption.section_3.start, dates.redemption.section_3.end, undefined, '[)')) {
                setOngoingEvent("double-12");
                setEventStatus({
                    event_1: 'ended',
                    event_2: 'ended',
                    event_3: 'ongoing',
                    event_4: 'soon'
                });
            } else if (now.isBetween(dates.redemption.section_3.end, dates.redemption.section_4.start, undefined, '[)')) {
                setOngoingEvent(null);
                setEligibilityCheck(false);
                setEventStatus({
                    event_1: 'ended',
                    event_2: 'ended',
                    event_3: 'ended',
                    event_4: 'soon'
                });
            } else if (now.isBetween(dates.redemption.section_4.start, dates.redemption.section_4.end, undefined, '[)')) {
                setOngoingEvent("new-year");
                setEventStatus({
                    event_1: 'ended',
                    event_2: 'ended',
                    event_3: 'ended',
                    event_4: 'ongoing'
                });
            } else {
                setOngoingEvent(null);
                setEligibilityCheck(false);
                setEventStatus({
                    event_1: 'ended',
                    event_2: 'ended',
                    event_3: 'ended',
                    event_4: 'ended'
                });
            }
        }
    };

    const checkCookie = () => {
        const cookie = localStorage.getItem('uuid');

        if (cookie) {
            const item = JSON.parse(cookie);
            const now = new Date();

            if (now.getTime() > item.expiry) {
                localStorage.removeItem(cookie);

                if (loginState && !refreshState) {
                    window.location.reload(false);
                    setRefreshState(true);
                }
            }
        }
    };

    const checkEligibility = async(event) => {
        if (uuid && (localStorage.getItem("event") !== event)) {
            const eligibility = await axios({
                url: '/api/v1/crm/redEnvelopes/timeLimited',
                method: 'GET',
                params: {
                    uuid
                }
            });

            if (eligibility.data.code === 0) {
                localStorage.setItem("event", event);

                if (eligibility.data.data.isReceived === 0) {
                    setEventFlag(event);
                }
            }
        }

        if (!uuid) {
            setEventFlag(event);
        }

        setEligibilityCheck(true);
    };

    const setEventFlag = (event) => {
        if (!localStorage.getItem(event)) {
            openPopup('redeem', {
                action: 'manual',
                event
            });
        }
        localStorage.setItem(event, "true");
    };

    return (
        <div className="content">
            <Timeline />
            {
                !uuid && (language === "sc") &&
                <Comments
                    uuid={uuid}
                    isChina={isChina}
                    config={config}
                    language={language}
                    maxChar={30}
                    scrollerText={luckyDraw}
                    commentCount={commentCount}
                    triggerPopup={openPopup.bind(this)} />
            }
            {
                uuid && isChina && (language === "sc") &&
                <Comments
                    uuid={uuid}
                    isChina={isChina}
                    config={config}
                    language={language}
                    maxChar={30}
                    scrollerText={luckyDraw}
                    commentCount={commentCount}
                    triggerPopup={openPopup.bind(this)} />
            }
            <Rewards
                uuid={uuid}
                config={config}
                language={language}
                accountInfo={accountInfo}
                triggerPopup={openPopup.bind(this)} />
            <Guide
                eventStatus={eventStatus}/>
            <Terms
                language={language} />
        </div>
    )
}

export default Content;
