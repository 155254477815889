import React from 'react';
import Select from 'react-select';

import './index.scss';

const Dropdown = (props) => {
    const { className, options, styles, placeholder, changeSelected, value } = props;

    const handleChange = (selected) => {
        changeSelected(selected);
    };

    return (
        <Select
            className={className}
            classNamePrefix="react-select"
            options={options}
            styles={styles}
            placeholder={placeholder}
            onChange={handleChange}
            isSearchable={false}
            value={value}/>
    );
}

export default Dropdown;
