import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Parser, Player } from 'svga';

import './scss/redeem-success.scss';

const RedeemSuccess = (props) => {
    const { content, config, accountInfo, close, triggerPopup, checkPackets, language } = props;
    const { t } = useTranslation();

    const [displayAmount, setDisplayAmount] = useState(false);
    const [infoText, setInfoText] = useState(null);
    const [buttonText, setButtonText] = useState(null);
    const [depositLink, setDepositLink] = useState(false);

    useEffect(() => {
        checkRedemptionType();
        loadAnimation();
        checkRemainingPackets();
    }, []);

    useEffect(() => {
        checkRedemptionType();
    }, [accountInfo]);

    const checkRemainingPackets = () => {
        checkPackets();
    };

    const loadAnimation = async() => {
        const parser = new Parser();
        const svga = await parser.load('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/open-packet.svga');

        const container = document.getElementById('svga-loader');
        const player = new Player(container);
        await player.mount(svga);

        player.start();
        setTimeout(() => {
            player.pause();
            setDisplayAmount(true);
        }, 800);
    };

    const checkRedemptionType = () => {
        switch(content.event) {
            case 'classic':
                if (accountInfo.commonNotReceivingAmount > 0) {
                    setInfoText(t('rewards.packets.more'));
                    setButtonText(t('rewards.continue'));
                    setDepositLink(false);
                } else {
                    setInfoText(t('rewards.packets.none'));
                    setButtonText(t('rewards.ok'));
                    setDepositLink(true);
                }
                break;
            case 'exclusive':
                if (accountInfo.specialNotReceivingAmount > 0) {
                    setInfoText(t('rewards.packets.more'));
                    setButtonText(t('rewards.continue'));
                    setDepositLink(false);
                } else {
                    setInfoText(t('rewards.packets.none'));
                    setButtonText(t('rewards.ok'));
                    setDepositLink(true);
                }
                break;
            case 'welcome':
                if (accountInfo.newNotReceivingAmount > 0) {
                    setInfoText(t('rewards.packets.more'));
                    setButtonText(t('rewards.continue'));
                    setDepositLink(false);
                } else {
                    setInfoText(t('rewards.packets.none'));
                    setButtonText(t('rewards.ok'));
                    setDepositLink(true);
                }
                break;
            case 'double-11':
            case 'anniversary':
            case 'double-12':
            case 'new-year':
                setButtonText(null);
                break;
        }
    };

    const redemptionAction = () => {
        switch(content.event) {
            case 'classic':
                if (accountInfo.commonNotReceivingAmount > 0) {
                    openPopup('redeem', {
                        action: 'auto',
                        event: content.event
                    });
                } else {
                    close();
                }
                break;
            case 'exclusive':
                if (accountInfo.specialNotReceivingAmount > 0) {
                    openPopup('redeem', {
                        action: 'auto',
                        event: content.event
                    });
                } else {
                    close();
                }
                break;
            case 'welcome':
                if (accountInfo.newNotReceivingAmount > 0) {
                    openPopup('redeem', {
                        action: 'auto',
                        event: content.event
                    });
                } else {
                    close();
                }
                break;
        }
    };

    const redirectDeposit = () => {
        window.location.href = config.crm_client;
    }

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    return (
        <div className={"modal-container redeem-success " + language }>
            <div className="modal">
                <button className="close" onClick={close}>
                    ×
                </button>
                <canvas id="svga-loader" />
                <div id="packet-amount" style={{display: displayAmount ? "block": "none"}}>
                    <div className="title">{t('rewards.congratulations')}</div>
                    <div className="number">
                        <div>{content.packets}</div>
                        <span>USD</span>
                    </div>
                </div>
                {
                    buttonText &&
                        <>
                            <div className="text-wrapper">
                                <div className="info">{infoText}</div>
                                <div className="button-get"
                                     style={{display: displayAmount ? "flex": "none"}}
                                     onClick={() => redemptionAction()}>
                                    {buttonText}
                                </div>
                                {
                                    depositLink &&
                                        <div className="deposit" onClick={() => redirectDeposit()}>{t('rewards.link')}</div>
                                }
                                <a>{t('rewards.trade-now')}</a>
                            </div>
                            <div className="disclaimer">
                                {t('rewards.wallet')}
                            </div>
                        </>
                }
            </div>
        </div>
    );
}

export default RedeemSuccess;
