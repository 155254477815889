import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './scss/redirection.scss';

const Redirection = (props) => {
    const { close } = props;

    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            window.location.href = t('redirection.link');
        }, 5000);
    }, []);

    return (
        <div className="modal-container redirection">
            <div className="modal">
                <button className="close" onClick={close}>
                    ×
                </button>
                <header className="modal-header">
                    <div className="popup-wrapper">
                        <article>
                            <p dangerouslySetInnerHTML={{__html: t('redirection.header')}} />
                        </article>
                        <footer>
                            <p>{t('redirection.info')}</p>
                            <a href={t('redirection.link')}>{t('redirection.button')}</a>
                        </footer>
                    </div>
                </header>
            </div>
        </div>
    );
}

export default Redirection;
