import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from "react-i18next";
import Collapsible from 'react-collapsible';

import './scss/footer.scss';

import countries from '../../data/jurisdiction_country.json';
import axios from "axios";

const Footer = (props) => {
    const { language } = props;
    const [jurisdictionGroup, setJurisdictionGroup] = useState(null);
    const [jurisdictionText, setJurisdictionText] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const getCountry = async() => {
            const country = await axios({
                url: '/api/v1/location/',
                method: 'GET'
            });

            if (country.data.code === 0) {
                const filter = countries.filter(data => {
                    return data.name === country.data.data.country;
                });

                if (filter.length > 0) {
                    let group = '';
                    let key = '';

                    if (filter[0].group) {
                        group = filter[0].group;
                    } else {
                        group = "others";
                    }

                    switch (language) {
                        case "sc":
                            key = filter[0].name_sc;
                            break;
                        case "tc":
                            key = filter[0].name_tc;
                            break;
                        default:
                            key = filter[0].key;
                            break;
                    }

                    checkGroup(group, key);
                    setJurisdictionGroup(group);
                }
            }
        }

        const checkGroup = (group, key) => {
            let text = '';

            switch (group) {
                case "eu":
                    text = t('footer.jurisdiction-eu').replace('<span></span>', '<span>' + key + '</span>');
                    break;
                case "uk":
                    text = t('footer.jurisdiction-uk');
                    break;
                case "aus":
                    text = t('footer.jurisdiction-aus');
                    break;
                case "others":
                    text = t('footer.jurisdiction').replace('<span></span>', '<span>' + key + '</span>');
                    break;
            }

            setJurisdictionText(text);
        }

        getCountry();
    }, [language]);

    return (
        <section className="footer">
            <footer className="footer-content">
                <div className="top-footer">
                    <div className="container">
                        <div>
                            <div className="grid column6 hide_sm">
                                <div className="column remove_arrow">
                                    <div className="social">
                                        <a target="_blank" rel="noopener" className="brand">
                                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/doo-prime-logo-footer.svg" alt="Doo Prime Logo" />
                                        </a>
                                        <div className="text">
                                            <div>
                                                <p className="title">{t('footer.contact')}</p>
                                                <p>{t('footer.contact-1')}</p>
                                                <p>{t('footer.contact-2')}</p>
                                                <p>{t('footer.contact-3')}</p>
                                            </div><br/>
                                            <div>
                                                <p className="title">{t('footer.email')}</p>
                                                <p>{t('footer.email-1')}</p>
                                                <p>{t('footer.email-2')}</p>
                                            </div>
                                        </div>
                                        <div className="hide_sm">
                                            <div className="pull_right">
                                                <a target="_blank" rel="noopener" href="https://www.facebook.com/DooPrime/">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/facebook.png" alt="Facebook" />
                                                </a>
                                                <a target="_blank" rel="noopener" href="https://www.instagram.com/dooprime/">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/instagram.png" alt="Instagram" />
                                                </a>
                                                <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/dooprime">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/linkedin.png" alt="LinkedIn" />
                                                </a>
                                                <a target="_blank" rel="noopener" href="https://twitter.com/dooprime">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/twitter.png" alt="Twitter" />
                                                </a>
                                                <a className="qr_wechat">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/wechat.png" alt="WeChat" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.markets')}</h5>
                                    <div className="content">
                                        <p>{t('footer.forex')}</p>
                                        <p>{t('footer.precious-metal')}</p>
                                        <p>{t('footer.energies')}</p>
                                        <p>{t('footer.spot-index')}</p>
                                        <p>{t('footer.securities')}</p>
                                        <p>{t('footer.futures')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.tools')}</h5>
                                    <div className="content">
                                        <span>{t('footer.trading-software')}</span>
                                        <p>{t('footer.metatrader-4')}</p>
                                        <p>{t('footer.metatrader-5')}</p>
                                        <p>{t('footer.dooprime-intrade')}</p>
                                        <p>{t('footer.trading-view')}</p>
                                        <p className="mb14">{t('footer.fix-api')}</p>
                                        <span>{t('footer.social-trading')}</span>
                                        <p>{t('footer.dooprime-outrade')}</p>
                                        <p className="mb14">{t('footer.myfxbook')}</p>
                                        <p>{t('footer.follow-me')}</p>
                                        <span>{t('footer.market-analysis')}</span>
                                        <p>{t('footer.trading-central')}</p>
                                        <p className="mb14">{t('footer.economic-calendar')}</p>
                                        <span>{t('footer.trading-tools')}</span>
                                        <p>{t('footer.vps')}</p>
                                        <p>{t('footer.realtime-history')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.trading-footer')}</h5>
                                    <div className="content">
                                        <p className="mb14">{t('footer.pricing-model')}</p>
                                        <p>{t('footer.technology')}</p>
                                        <p>{t('footer.account-comparison')}</p>
                                        <p>{t('footer.funding')}</p>
                                        <span>{t('footer.trading-conditions')}</span>
                                        <p>{t('footer.leverage')}</p>
                                        <p>{t('footer.overnight-interest')}</p>
                                        <p>{t('footer.trading-calendar')}</p>
                                        <p>{t('footer.contract-specification')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.partners')}</h5>
                                    <div className="content">
                                        <span>{t('footer.membership')}</span>
                                        <p>{t('footer.membership-rewards')}</p>
                                        <span>{t('footer.partner')}</span>
                                        <p>{t('footer.introducing-broker')}</p>
                                        <p>{t('footer.white-label-partner')}</p>
                                        <p>{t('footer.regional-offices')}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <h5 className="collapsible">{t('footer.about-us')}</h5>
                                    <div className="content">
                                        <p>{t('footer.our-story')}</p>
                                        <p>{t('footer.csr')}</p>
                                        <p>{t('footer.media-centre')}</p>
                                        <p>{t('footer.compliance')}</p>
                                        <p>{t('footer.contact-us')}</p>
                                        <p>{t('footer.career')}</p>
                                        <p>{t('footer.help')}</p>
                                    </div>
                                </div>
                                {/*<div className="column">*/}
                                {/*    <h5 className="collapsible">{t('footer.help')}</h5>*/}
                                {/*</div>*/}
                            </div>
                            <div className="hide_mdlg">
                                <div className="column remove_arrow">
                                    <div className="social">
                                        <a target="_blank" rel="noopener" className="brand">
                                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/doo-prime-logo-footer.svg" alt="Doo Prime Logo" />
                                        </a>
                                        <div className="text">
                                            <div>
                                                <p className="title">{t('footer.contact')}</p>
                                                <p>{t('footer.contact-1')}</p>
                                                <p>{t('footer.contact-2')}</p>
                                                <p>{t('footer.contact-3')}</p>
                                            </div><br/>
                                            <div>
                                                <p className="title">{t('footer.email')}</p>
                                                <p>{t('footer.email-1')}</p>
                                                <p>{t('footer.email-2')}</p>
                                            </div>
                                        </div>
                                        <div className="sm-container">
                                            <div className="pull_right">
                                                <a target="_blank" rel="noopener" href="https://www.facebook.com/DooPrime/">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/facebook.png" alt="Facebook" />
                                                </a>
                                                <a target="_blank" rel="noopener" href="https://www.instagram.com/dooprime/">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/instagram.png" alt="Instagram" />
                                                </a>
                                                <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/dooprime">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/linkedin.png" alt="LinkedIn" />
                                                </a>
                                                <a target="_blank" rel="noopener" href="https://twitter.com/dooprime">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/twitter.png" alt="Twitter" />
                                                </a>
                                                <a className="qr_wechat">
                                                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/wechat.png" alt="WeChat" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.markets')} >
                                        <div className="content">
                                            <p>{t('footer.forex')}</p>
                                            <p>{t('footer.precious-metal')}</p>
                                            <p>{t('footer.energies')}</p>
                                            <p>{t('footer.spot-index')}</p>
                                            <p>{t('footer.securities')}</p>
                                            <p>{t('footer.futures')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.tools')} >
                                        <div className="content">
                                            <span>{t('footer.trading-software')}</span>
                                            <p>{t('footer.metatrader-4')}</p>
                                            <p>{t('footer.metatrader-5')}</p>
                                            <p>{t('footer.dooprime-intrade')}</p>
                                            <p>{t('footer.trading-view')}</p>
                                            <p className="mb14">{t('footer.fix-api')}</p>
                                            <span>{t('footer.social-trading')}</span>
                                            <p>{t('footer.dooprime-outrade')}</p>
                                            <p className="mb14">{t('footer.myfxbook')}</p>
                                            <p>{t('footer.follow-me')}</p>
                                            <span>{t('footer.market-analysis')}</span>
                                            <p>{t('footer.trading-central')}</p>
                                            <p className="mb14">{t('footer.economic-calendar')}</p>
                                            <span>{t('footer.trading-tools')}</span>
                                            <p>{t('footer.vps')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.trading-footer')} >
                                        <div className="content">
                                            <p className="mb14">{t('footer.pricing-model')}</p>
                                            <p>{t('footer.technology')}</p>
                                            <p>{t('footer.account-comparison')}</p>
                                            <p>{t('footer.funding')}</p>
                                            <span>{t('footer.trading-conditions')}</span>
                                            <p>{t('footer.leverage')}</p>
                                            <p>{t('footer.overnight-interest')}</p>
                                            <p>{t('footer.trading-calendar')}</p>
                                            <p>{t('footer.contract-specification')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.partners')} >
                                        <div className="content">
                                            <span>{t('footer.membership')}</span>
                                            <p>{t('footer.membership-rewards')}</p>
                                            <span>{t('footer.partner')}</span>
                                            <p>{t('footer.introducing-broker')}</p>
                                            <p>{t('footer.white-label-partner')}</p>
                                            <p>{t('footer.regional-offices')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible trigger={t('footer.about-us')} >
                                        <div className="content">
                                            <p>{t('footer.our-story')}</p>
                                            <p>{t('footer.csr')}</p>
                                            <p>{t('footer.media-centre')}</p>
                                            <p>{t('footer.compliance')}</p>
                                            <p>{t('footer.contact-us')}</p>
                                            <p>{t('footer.career')}</p>
                                            <span>{t('footer.media-centre')}</span>
                                            <p>{t('footer.home')}</p>
                                            <p>{t('footer.important')}</p>
                                            <p>{t('footer.company-news')}</p>
                                            <p>{t('footer.event')}</p>
                                            <p>{t('footer.industry-dynamic')}</p>
                                            <p>{t('footer.analysis')}</p>
                                            <p>{t('footer.help')}</p>
                                        </div>
                                    </Collapsible>
                                </div>
                                <div className="column">
                                    <Collapsible />
                                </div>
                            </div>
                            <div className="column hide_mdlg remove_arrow alignCenter social_media">
                                <div className="pull_right">
                                    <a target="_blank" rel="noopener" href="https://www.facebook.com/DooPrime/">
                                        <img src="https://cdn.dootech.io/prime-website2/images/icon-share-Facebook.svg" alt="Facebook" />
                                    </a>
                                    <a target="_blank" rel="noopener" href="https://www.instagram.com/dooprime/">
                                        <img src="https://cdn.dootech.io/prime-website2/images/icon-share-INS.svg" alt="Instagram" />
                                    </a>
                                    <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/dooprime">
                                        <img src="https://cdn.dootech.io/prime-website2/images/icon-share-Linkedin.svg" alt="LinkedIn" />
                                    </a>
                                    <a target="_blank" rel="noopener" href="https://twitter.com/dooprime">
                                        <img src="https://cdn.dootech.io/prime-website2/images/icon-share-twitter.svg" alt="Twitter" />
                                    </a>
                                    <a className="qr_wechat">
                                        <img src="https://cdn.dootech.io/prime-website2/images/icon-share-WeChat.svg" alt="WeChat" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="middle-footer">
                    <div className="container">
                        <div className="compliance">
                            <h5 className="complianceTitle">{t('footer.compliance-disclosure')}</h5>
                            {
                                jurisdictionGroup === "eu" &&
                                    <p className="disclaimer-subtitle jurisdiction_eu" dangerouslySetInnerHTML={{__html: jurisdictionText}} />
                            }
                            {
                                jurisdictionGroup === "aus" &&
                                    <p className="disclaimer-subtitle jurisdiction_aus">{jurisdictionText}</p>
                            }
                            {
                                jurisdictionGroup === "uk" &&
                                    <p className="disclaimer-subtitle jurisdiction_uk">{jurisdictionText}</p>
                            }
                            {
                                jurisdictionGroup === "others" &&
                                    <p className="disclaimer-subtitle jurisdiction" dangerouslySetInnerHTML={{__html: jurisdictionText}} />
                            }
                        </div>
                        <div className="compliance-container">
                            <span>{t('footer.read-compliance-title')}</span>
                            <div dangerouslySetInnerHTML={{__html: t('footer.read-compliance')}} />
                        </div>
                        <div className="compliance_desc desktop">
                            <Tabs>
                                <TabList>
                                    <Tab>{t('footer.high-risk-title')}</Tab>
                                    <Tab>{t('footer.brand-entity-title')}</Tab>
                                    <Tab>{t('footer.client-notice-title')}</Tab>
                                    <Tab>{t('footer.doo-dropdown')}</Tab>
                                </TabList>
                                <TabPanel>
                                    <div dangerouslySetInnerHTML={{__html: t('footer.high-risk')}} />
                                </TabPanel>
                                <TabPanel>
                                    <div dangerouslySetInnerHTML={{__html: t('footer.brand-entity')}} />
                                </TabPanel>
                                <TabPanel>
                                    <div dangerouslySetInnerHTML={{__html: t('footer.client-notice')}} />
                                </TabPanel>
                                <TabPanel>
                                    <div className="bulletin">
                                        <div className="card">
                                            <h3>{t('footer.elish-elish-inc')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.elish-elish-inc-desc')}}/>
                                            <a href="https://www.doofinancial.com"
                                               target="_blank">www.doofinancial.com</a>
                                            <br className="mobile"/>
                                            <a href="https://www.elish.com" target="_blank">www.elish.com</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.doo-clearing-limited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.doo-clearing-limited-desc')}}/>
                                            <a href="https://www.dooclearing.co.uk"
                                               target="_blank">www.dooclearing.com</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.doo-consulting-limited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.doo-consulting-limited-desc')}}/>
                                            <a href="https://www.doogroup.com" target="_blank">www.doogroup.com</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.doo-technology-limited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.doo-technology-limited-desc')}}/>
                                            <a href="https://www.doo.tech" target="_blank">www.doo.tech</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.dooprime-vanautu-limited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.dooprime-vanautu-limited-desc')}}/>
                                            <a href="https://www.dooprimeglobal.com"
                                               target="_blank">www.dooprimeglobal.com</a>
                                            <a href="https://www.dooprime.com" target="_blank">www.dooprime.com</a>
                                        </div>
                                        <div className="card">
                                            <h3>{t('footer.dooprime-mauritius-limited')}</h3>
                                            <p dangerouslySetInnerHTML={{__html: t('footer.dooprime-mauritius-limited-desc')}}/>
                                            <a href="https://www.dooprime.mu" target="_blank">www.dooprime.mu</a>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                        <div className="compliance_desc mobile">
                            <ul className="complianceWrapper">
                                <li>
                                    <Collapsible trigger={t('footer.high-risk-title')} >
                                        <div dangerouslySetInnerHTML={{__html: t('footer.high-risk')}} />
                                    </Collapsible>
                                </li>
                                <li>
                                    <Collapsible trigger={t('footer.brand-entity-title')} >
                                        <div dangerouslySetInnerHTML={{__html: t('footer.brand-entity')}} />
                                    </Collapsible>
                                </li>
                                <li>
                                    <Collapsible trigger={t('footer.client-notice-title')} >
                                        <div dangerouslySetInnerHTML={{__html: t('footer.client-notice')}} />
                                    </Collapsible>
                                </li>
                                <li>
                                    <Collapsible trigger={t('footer.doo-dropdown')} >
                                        <div className="bulletin">
                                            <div className="card">
                                                <h3>{t('footer.elish-elish-inc')}</h3>
                                                <p dangerouslySetInnerHTML={{__html: t('footer.elish-elish-inc-desc')}}/>
                                                <a href="https://www.doofinancial.com"
                                                   target="_blank">www.doofinancial.com</a>
                                                <br className="mobile"/>
                                                <a href="https://www.elish.com" target="_blank">www.elish.com</a>
                                            </div>
                                            <div className="card">
                                                <h3>{t('footer.doo-clearing-limited')}</h3>
                                                <p dangerouslySetInnerHTML={{__html: t('footer.doo-clearing-limited-desc')}}/>
                                                <a href="https://www.dooclearing.co.uk"
                                                   target="_blank">www.dooclearing.com</a>
                                            </div>
                                            <div className="card">
                                                <h3>{t('footer.doo-consulting-limited')}</h3>
                                                <p dangerouslySetInnerHTML={{__html: t('footer.doo-consulting-limited-desc')}}/>
                                                <a href="https://www.doogroup.com" target="_blank">www.doogroup.com</a>
                                            </div>
                                            <div className="card">
                                                <h3>{t('footer.doo-technology-limited')}</h3>
                                                <p dangerouslySetInnerHTML={{__html: t('footer.doo-technology-limited-desc')}}/>
                                                <a href="https://www.doo.tech" target="_blank">www.doo.tech</a>
                                            </div>
                                            <div className="card">
                                                <h3>{t('footer.dooprime-vanautu-limited')}</h3>
                                                <p dangerouslySetInnerHTML={{__html: t('footer.dooprime-vanautu-limited-desc')}}/>
                                                <a href="https://www.dooprimeglobal.com"
                                                   target="_blank">www.dooprimeglobal.com</a>
                                                <a href="https://www.dooprime.com" target="_blank">www.dooprime.com</a>
                                            </div>
                                            <div className="card">
                                                <h3>{t('footer.dooprime-mauritius-limited')}</h3>
                                                <p dangerouslySetInnerHTML={{__html: t('footer.dooprime-mauritius-limited-desc')}}/>
                                                <a href="https://www.dooprime.mu" target="_blank">www.dooprime.mu</a>
                                            </div>
                                        </div>
                                    </Collapsible>
                                </li>
                                <li>
                                    <Collapsible />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer_menu">
                        <div className="container">
                            <a href='https://cdn.dootech.io/prime-website2/docs/Client_Agreement_Doo_Prime_Vanuatu_Limited_Aug_2021.pdf' target="_blank">{t('footer.customer-identification')}</a>
                            <a href='https://cdn.dootech.io/prime-website2/docs/AMLAndCTFPolicy-Doo-Prime-Vanuatu-Limited-16- December-2020.pdf' target="_blank">{t('footer.aml-ctf-policy')}</a>
                            <a href='https://cdn.dootech.io/prime-website2/docs/Execution_Policy_(Doo Prime).pdf' target="_blank">{t('footer.execution-policy')}</a>
                            <a href='https://cdn.dootech.io/prime-website2/docs/Refund_Policy_(Doo Prime).pdf' target="_blank">{t('footer.refund-policy')}</a>
                            <a href='https://cdn.dootech.io/prime-website2/docs/updated/Risk%20Disclosure%20And%20Acknowledgement%20(Doo%20Prime%20Vanuatu%20Limited).pdf' target="_blank">{t('footer.risk-acknowledgement')}</a>
                            <a href='https://cdn.dootech.io/prime-website2/docs/updated/Privacy%20Policy%20(Doo%20Prime%20Vanuatu%20Limited).pdf' target="_blank">{t('footer.privacy-policy')}</a>
                            <a href='https://cdn.dootech.io/prime-website2/docs/Website_Terms_and_Conditions_(Doo Prime).pdf' target="_blank" className="remove_border">{t('footer.website-tnc')}</a>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container web">
                            {t('footer.ct-copyright')}
                        </div>
                    </div>
                </div>
            </footer>
        </section>
    )
}

export default Footer;
