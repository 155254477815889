import React, { useState, useEffect } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import Mask from '../components/mask';
import Layout from '../components/layout';

const Routes = () => {
    const [mask, setMask] = useState(false);

    useEffect(() => {
        layerMask();
    }, [])

    function is_weixin(){
        const ua = navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i)=="micromessenger") {
            return true;
        }
    }

    const layerMask = () => {
        if (is_weixin()) {
            setMask(true);
        }
    }

    const redirectHelper = (props) => {
        const { history } = props;
        const path = history.location.pathname + history.location.search;

        if (path.indexOf('7years') === -1) {
            history.push(`/7years${path}`)
        }
        return null;
    };

    return (
        <Switch>
            <Route path={"/7years/:lang(en|sc|tc|vn|th)?/"} component={mask ? Mask : Layout} />
            <Route path="/7years" component={mask ? Mask : Layout} />
            <Route path="/:user?/:auth?/:redirectSignIn?/:token?" component={redirectHelper} />
        </Switch>
    );
}

export default Routes;
