import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import Marquee from 'react-marquee-slider';
import Loader from 'react-loader-spinner';
import Ticker from 'react-ticker';
import NewsTicker from 'react-advanced-news-ticker';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import times from 'lodash/times';
import axios from 'axios';

import './scss/comments.scss';
import 'react-toastify/dist/ReactToastify.css';

const predefined = [
    "风雨历程7载， 共创辉煌！️",
    "公司业绩日日增，本人收益年年长！",
    "7周年来了，祝永久!❤️",
    "7年风雨，7年成长，愿 Doo Group 续创辉煌！",
    "恭喜Doo 7周年！祝发展7开得胜!"
];

const timeOutArr = []
const evokeArray = []
const Comments = (props) => {
    const { uuid, isChina, config, language, maxChar, scrollerText, commentCount, triggerPopup } = props;
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [charLength, setCharLength] = useState(0);
    const [commentsFeed, setCommentsFeed] = useState([]);
    const [commentsLoading, setCommentsLoading] = useState(true);
    const [commentText, setCommentText] = useState('');
    const [commentPost, setCommentPost] = useState(false);
    const [imageHeight, setImageHeight] = useState(0);
    const [mapHeight, setMapHeight] = useState(0);
    const { t } = useTranslation();

    const imageRef = useRef();
    const amountRef = useRef();

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [language]);

    useEffect(() => {
        if (commentPost) {
            const updateComment = commentsFeed;
            updateComment.shift();
            updateArray(updateComment, true);
            setCommentPost(false);
            setCommentsLoading(true);
        }
    }, [commentPost]);

    useEffect(() => {
        const getCommentFeed = async() => {
            const comments = await axios({
                url: '/api/v1/comments/blessing/get',
                method: 'GET'
            });

            if (comments.data.code === 0) {
                comments.data.feed.forEach(x => {
                    evokeArray.push(x)
                })
                updateArray(comments.data.feed.map(comment => ({...comment, type: 'normal'}), false, 'evoke trigger'));
            }
        };

        getCommentFeed();

        updateContainerHeight();
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', updateContainerHeight);
        return () => window.removeEventListener('resize', updateContainerHeight);
    }, []);

    const updateContainerHeight = () => {
        const commentsContainerHeight = imageRef.current.clientHeight - amountRef.current.clientHeight - 30;
        setImageHeight(commentsContainerHeight);
        setMapHeight(imageRef.current.clientHeight);
    }

    const updateArray = async (feed, clearTimer, convoke) => {
        // const updated = feed.map(comment => ({...comment, type: 'normal'}));
        const array = await chunkArray(feed, 5);
        for (let i = 0; i < 1000; i++) {
            if (clearTimer) {
                refreshTimeOut(timeOutArr)
                updateArray(evokeArray, false, 'this is a convokation')
                break
            }

            timeOutArr.push(setTimeout(() => {
                setCommentsFeed(array[i % array.length]);
            }, (i+1) * 10000 ))
        }
    };

    const refreshTimeOut = (timeOutArr) => {
        timeOutArr.forEach(function(timer) {
            clearTimeout(timer)
        })
    }
    const chunkArray = (array, size) => {
        let data = [];

        for (let index = 0; index < array.length; index += size) {
            data.push(array.slice(index, index + size));
        }
        return data;
    };

    const handleCharacterCount = (value) => {
        if (value.length > maxChar) {
            setDisableSubmit(true);
        }
        setCharLength(value.length);
    };

    const handleChange = (event) => {
        const text = event.target.value;
        handleCharacterCount(text);
        setCommentText(text);
    };

    const insertComment = (index) => {
        const text = predefined[index];
        handleCharacterCount(text);
        setCommentText(text);
    };

    const includeComment = (text) => {
        let comments = commentsFeed;
        let payload = {
            "type": "new",
            "content": text,
            "uuid": uuid || 'the_added_user'
        }
        evokeArray.splice(0,0,payload)
        comments.unshift(payload);
        updateArray(comments, false, 'invoking the addition of a new comment');
        setCommentPost(true);
        setCommentText('');
        setCommentsLoading(true);
    };

    const loadingFlag = (loading = true) => {
        setCommentsLoading(loading);
    };

    const submitComment = async() => {
        if (uuid && isChina) {
            const submitComment = await axios({
                url: '/api/v1/comments/blessing/add',
                method: 'POST',
                data: {
                    uuid,
                    content: commentText
                }
            });

            if (submitComment.data.code === 0) {
                toast.success(t('comments.success'));
            } else {
                switch (submitComment.data.code) {
                    case "5000":
                        toast.error("抱歉，用户不存在");
                        break;
                    case "5011":
                        toast.error("抱歉，输入的内容不适用，请重新输入");
                        break;
                }
            }

            includeComment(commentText);
        } else {
            window.location.href = config.crm_client;
        }
    };

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    return (
        <div className="comments">
            <div className="comments-container">
                <div className="title">{t('comments.title')}</div>
                <div className="desc" dangerouslySetInnerHTML={{__html: t('comments.desc')}}/>
                <div className="info-box">
                    <Ticker offset="run-in" speed={10}>
                        {() => (
                            <div>{scrollerText}</div>
                        )}
                    </Ticker>
                </div>
                <div className="comments-box desktop">
                    <img className="comments-box-img" ref={imageRef}
                        src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/comments.svg" alt="Comments" />
                    <img style={{height: mapHeight + 'px'}}
                        className="map-img" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/map.svg" alt="Map" />
                    <div className="comments-box-container">
                        <div className="amount" ref={amountRef}>
                            <span>{t('comments.box.title')}</span>
                            <div className="amount-box">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/heart.png" alt="Heart" />
                                {
                                    commentCount.toString().split('').map(character => {
                                       return <span>{character}</span>
                                    })
                                }
                            </div>
                        </div>
                        <div className="comments-display">
                            <div style={{width: '100%', height: imageHeight + 'px', position: 'relative'}}>
                                <Marquee
                                    key={commentsFeed.length}
                                    children={commentsFeed}
                                    direction={"rtl"}
                                    velocity={50}
                                    scatterRandomly
                                    minScale={0.7}
                                    maxScale={1}
                                    resetAfterTries={200}
                                    onFinish={() => loadingFlag(false)} >
                                    {
                                        times(commentsFeed.length, String).map(id =>
                                            <div key={id} id="individual-comment" className={commentsFeed[id] ? commentsFeed[id].type : ''}>
                                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/red-line.svg" alt="Line" />
                                                <span className="comment-content">{commentsFeed[id] ? commentsFeed[id].content : ''}</span>
                                                <span className="comment-author">{commentsFeed[id] ? commentsFeed[id].uuid : ''}</span>
                                            </div>
                                        )
                                    }
                                </Marquee>
                                {
                                    commentsLoading ? <div className="loading">
                                        <Loader
                                            type="ThreeDots"
                                            color="#d91d22"
                                            height={80}
                                            width={80} />
                                    </div> : <></>
                                }
                            </div>
                        </div>
                    </div>
                    {/*<div className="predefined-comments">*/}
                    {/*    {*/}
                    {/*        predefined.map((comment, index) => {*/}
                    {/*            return <div onClick={() => insertComment(index)}>{comment}</div>*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className="comments-send">
                        <div className="comment-input">
                            <input
                                onChange={handleChange}
                                maxLength={maxChar}
                                value={commentText}/>
                            <div className="button">
                                <span>{charLength + "/30"}</span>
                                <button onClick={() => submitComment()} disabled={disableSubmit || !commentText.length}>{t('comments.submit')}</button>
                            </div>
                        </div>
                        <span className="comment-terms" data-tip={t('comments.tooltip')} data-text-color="#313131" data-background-color="white" data-place="bottom">{t('comments.terms')}</span>
                    </div>
                </div>
                <div className="comments-box mobile">
                    <div className="comments-box-container">
                        <div className="amount">
                            <span>{t('comments.box.title')}</span>
                            <div className="amount-box">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/heart.png" alt="Heart" />
                                {
                                    commentCount.toString().split('').map(character => {
                                        return <span>{character}</span>
                                    })
                                }
                            </div>
                        </div>
                        <div className="container">
                            <img className="comments-box-img" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/comments.svg" alt="Comments" />
                            <div className="comments-display">
                                {
                                    commentsFeed.length > 0 &&
                                        <NewsTicker
                                            key={commentsFeed.length}
                                            rowHeight = {30}
                                            maxRows = {1}
                                            speed = {600}
                                            duration = {3000}
                                            autoStart = {true}
                                            pauseOnHover = {false}
                                            className = "comments-ticker">
                                            {
                                                times(commentsFeed.length, String).map(id =>
                                                    <div key={id} id="individual-comment" className={commentsFeed[id] ? commentsFeed[id].type : ''}>
                                                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/red-line.svg" alt="Line"/>
                                                        <span  className="comment-content">{commentsFeed[id] ? commentsFeed[id].content : ''}</span>
                                                        <span className="comment-author">{commentsFeed[id] ? commentsFeed[id].uuid : ''}</span>
                                                    </div>
                                                )
                                            }
                                        </NewsTicker>
                                }
                            </div>
                        </div>
                        {/*<div className="predefined-comments">*/}
                        {/*    {*/}
                        {/*        predefined.map((comment, index) => {*/}
                        {/*            return <div onClick={() => insertComment(index)}>{comment}</div>*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*</div>*/}
                        <div className="comments-send">
                            <div className="comment-input">
                                <input
                                    onChange={handleChange}
                                    maxLength={maxChar}
                                    value={commentText} />
                                <div className="button">
                                    <span>{charLength + "/30"}</span>
                                </div>
                            </div>
                            <div className="submit">
                                <span className="comment-terms" onClick={() => openPopup('info', t('comments.tooltip'))}>{t('comments.terms')}</span>
                                <button onClick={() => submitComment()} disabled={!commentText.length}>{t('comments.send')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactTooltip className="tooltip" html={true} effect="solid"/>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar />
        </div>
    )
}

export default Comments;
