import React from 'react';
import scroll from 'react-scroll';
import { useTranslation } from "react-i18next";

import './scss/header.scss';

const Header = (props) => {
    const { language, uuid, triggerPopup } = props;
    const { t } = useTranslation();

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    const redirectAction = () => {
        if (uuid) {
            scroll.scroller.scrollTo('user-records', {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -120
            });
        } else {
            openPopup('crm-register');
        }
    };

    return (
        <div className={"banner " + language}>
            <div className="banner-container">
                <div className="banner-text">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/banner-logo@3x.png" alt="Banner Logo" />
                    <div className="title" dangerouslySetInnerHTML={{__html: t('header.title')}} />
                    <div className="desc">{t('header.desc')}</div>
                    <div className="button desktop" onClick={() => redirectAction()}>{uuid ? t('header.record') : t('header.participate')}</div>
                </div>
                <div className="button mobile" onClick={() => redirectAction()}>{uuid ? t('header.record') : t('header.participate')}</div>
            </div>
        </div>
    )
}

export default Header;
