import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './scss/redeem.scss';

const Redeem = (props) => {
    const { uuid, config, close, content, triggerPopup, language } = props;

    const { t } = useTranslation();

    useEffect(() => {
        if (uuid && content.action === 'auto') {
            handleRedeem(checkEventType());
        }
    }, []);

    const redeemPacket = () => {
        if (uuid) {
            handleRedeem(checkEventType());
        } else {
            window.location.href = config.crm_client;
        }
    };

    const handleRedeem = async(type) => {
        const submitForm = await axios({
            url: '/api/v1/crm/redEnvelopes/get',
            method: 'PUT',
            data: {
                uuid,
                type
            }
        });
        

        if (submitForm.data.code === 0) {
            openPopup('redeem-success', {
                packets: submitForm.data.data.redEnvelopes,
                event: content.event
            });
        } else {
            let error = '';

            switch(submitForm.data.code) {
                case 429:
                case 1001:
                case 5000:
                case 5002:
                case 5003:
                case 5004:
                case 5005:
                case 5007:
                case 5009:
                    error = t('rewards.error.' + submitForm.data.code.toString());
                    break;
                default:
                    error = t('rewards.error.1000');
                    break;
            }
            openPopup('redeem-fail', error);
        }
    };

    const checkEventType = () => {
        let type = '';

        switch (content.event) {
            case 'classic':
                type = "2";
                break;
            case 'exclusive':
                type = "1";
                break;
            case 'welcome':
                type = "3";
                break;
            case 'double-11':
            case 'anniversary':
            case 'double-12':
            case 'new-year':
                type = "4";
                break;
        }

        return type;
    };

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    return (
        <div className={"modal-container redeem " + language}>
            <div className="modal">
                <button className="close" onClick={close}>
                    ×
                </button>
                <span>{t('rewards.packets.title')}</span>
                {
                    content.action === 'manual' &&
                        <div className="button-get" onClick={() => redeemPacket()}>{t('rewards.redeem')}</div>
                }
            </div>
        </div>
    );
}

export default Redeem;
