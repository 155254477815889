import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';

import './scss/guide.scss';

const Guide = (props) => {
    const { eventStatus } = props;
    const [containerHeight, setContainerHeight] = useState();
    const { t } = useTranslation();

    const guideContentRef = useRef(null);

    useEffect(() => {
        function updateSize() {
            updateContainerSize();
        }
        window.addEventListener('resize', updateSize);
        window.addEventListener('load', updateSize);
        updateSize();
        return () => window.removeEventListener('resize load', updateSize);
    }, []);

    const updateContainerSize = () => {
        const contentHeight = guideContentRef.current.clientHeight + 50;
        setContainerHeight(contentHeight);
    };

    return (
        <div className="guide" style={{height: containerHeight + 'px'}}>
            <div className="guide-container" ref={guideContentRef}>
                <div className="text">
                    <div className="title">{t('guide.title')}</div>
                    <div className="desc">
                        {t('guide.desc')}
                        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/packets.png" alt="Packets"/>
                    </div>
                    <div className="event">
                        <div className="number">1</div>
                        <div className="list">
                            <div className="title">
                                {t('guide.event-1.title')}
                                <div className={"tag " + eventStatus.event_1}>{eventStatus.event_1 === 'soon' ? t('guide.status.soon') : (eventStatus.event_1 === 'ongoing') ? t('guide.status.ongoing') : t('guide.status.ended')}</div>
                            </div>
                            <div className="desc">{t('guide.event-1.desc')}</div>
                        </div>
                    </div>
                    <div className="event">
                        <div className="number">2</div>
                        <div className="list">
                            <div className="title">
                                {t('guide.event-2.title')}
                                <div className={"tag " + eventStatus.event_2}>{eventStatus.event_2 === 'soon' ? t('guide.status.soon') : (eventStatus.event_2 === 'ongoing') ? t('guide.status.ongoing') : t('guide.status.ended')}</div>
                            </div>
                            <div className="desc">{t('guide.event-2.desc')}</div>
                        </div>
                    </div>
                    <div className="event">
                        <div className="number">3</div>
                        <div className="list">
                            <div className="title">
                                {t('guide.event-3.title')}
                                <div className={"tag " + eventStatus.event_3}>{eventStatus.event_3 === 'soon' ? t('guide.status.soon') : (eventStatus.event_3 === 'ongoing') ? t('guide.status.ongoing') : t('guide.status.ended')}</div>
                            </div>
                            <div className="desc">{t('guide.event-3.desc')}</div>
                        </div>
                    </div>
                    <div className="event">
                        <div className="number">4</div>
                        <div className="list">
                            <div className="title">
                                {t('guide.event-4.title')}
                                <div className={"tag " + eventStatus.event_4}>{eventStatus.event_4 === 'soon' ? t('guide.status.soon') : (eventStatus.event_4 === 'ongoing') ? t('guide.status.ongoing') : t('guide.status.ended')}</div>
                            </div>
                            <div className="desc">{t('guide.event-4.desc')}</div>
                        </div>
                    </div>
                    <div className="disclaimer">{t('guide.disclaimer')}</div>
                </div>
            </div>
        </div>
    )
}

export default Guide;
