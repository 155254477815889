import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import './scss/terms.scss';

const Terms = (props) => {
    const { language } = props;
    const { t } = useTranslation();

    return (
        <div className={"terms " + language} id="terms">
            <div className="terms-container">
                <div className="title">
                    <div>{t('terms.title')}</div>
                    <span dangerouslySetInnerHTML={{__html: t('terms.desc')}} />
                </div>
                <div className="line" />
                <div className="list" dangerouslySetInnerHTML={{__html: t('terms.list')}} />
            </div>
        </div>
    )
}

export default Terms;
