import React from 'react';
import { useTranslation } from 'react-i18next';

import './scss/timeline.scss';

const Timeline = () => {
    const { t } = useTranslation();

    return (
        <div className="introduction">
            <div className="introduction-container">
                <ul className="timeline">
                    <li data-year="2014" />
                    <li data-year="2015" />
                    <li data-year="2016" />
                    <li data-year="2017" />
                    <li data-year="2018" />
                    <li data-year="2019" />
                    <li data-year="2020" />
                    <li data-year="2021" />
                </ul>
                <div dangerouslySetInnerHTML={{__html: t('timeline.desc')}} />
            </div>
        </div>
    )
}

export default Timeline;
