import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from "react-i18next";

import './scss/crm-register.scss';

const CrmRegister = (props) => {
    const { language, config, close } = props;
    const [modalHeight, setModalHeight] = useState('100vh');
    const { t } = useTranslation();

    const contentRef = useRef(null);

    useEffect(() => {
        let hl = '';

        switch(language) {
            case "en":
                hl = "en";
                break;
            case "sc":
                hl = "zh";
                break;
            case "tc":
                hl = "tw";
                break;
            case "kr":
                hl = "ko";
                break;
            case "vn":
                hl = "vi";
                break;
            case "th":
                hl = "th";
                break;
            default:
                break;
        }

        const script = config.crm_client + "/crmv5/support/landpage.js";
        const html = `
            <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.3, minimal-ui, width=device-width, user-scalable=no, shrink-to-fit=no, target-densitydpi=device-api">
            <title>land demo</title>
            <style>
               #land-iframe-wrapper {
                    width: 100%;
               }
               #land-iframe-wrapper.mobile {
                    margin: auto;
               }
            </style>
            <body>
                <div id="land-iframe-wrapper"></div>
            </body>
            <script>
                const el = document.getElementById('land-iframe-wrapper');
                if (window.navigator.appVersion.toLowerCase().match(/(iphone|ipod|ipad|android|symbian|mobile|windows phone|blackberry|windows ce)/)) {
                    el.className += 'mobile';
                }
            </script>
            <script type="text/javascript" id="crm-land-form" src=` + script + ` async> {
                    "width": "100%",
                    "height": "100%",
                    "hl": "` + hl +`",
                    "srcBase": "` + config.crm_client + `landingpage",
                    "colorTheme": "light",
                    "clueSource": "DP 7th Anniversary Landing Page"
                }
            </script>`;

        const fragment = document.createRange().createContextualFragment(
            `<div>${html}</div>`
        );

        if (contentRef.current) {
            contentRef.current.appendChild(fragment);
        }
    }, [language]);

    useEffect(() => {
        const contentHeight = contentRef.current.clientHeight + 100 + 'px';
        setModalHeight(contentHeight);
    }, []);

    return (
        <div className="modal-container crm-register">
            <div className="modal" style={{height: modalHeight}}>
                <button className="close" onClick={close}>
                    ×
                </button>
                <header className="modal-header">
                    <div className="popup-wrapper">
                        <h5 className="title">{t('header.lead-form.title')}</h5>
                        <p className="content">
                            {t('header.lead-form.desc')}<span onClick={() => window.location.href = config.crm_client}>{t('header.lead-form.login')}</span>
                        </p>
                        <div ref={contentRef} />
                    </div>
                </header>
            </div>
        </div>
    );
}

export default CrmRegister;
