import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import postscribe from 'postscribe';
import disableDevtool from'disable-devtool';

import Navbar from './navbar';
import Header from './header';
import Content from './content';
import Footer from './footer';
import PopUp from '../popup';

import './scss/index.scss';

const Layout = () => {
    const [language, setLanguage] = useState('en');
    const [config, setConfig] = useState({});
    const [accountInfo, setAccountInfo] = useState({});
    const [popup, setPopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [popupContent, setPopupContent] = useState(null);

    const { t, i18n } = useTranslation();

    const getParamString = (name, url) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    useEffect(() => {
        const getConfig = async() => {
            const config = await axios({
                url: '/api/v1/config?crm_client',
                method: 'GET'
            });
            console.log("config", config);

            if (config.data.config) {
                setConfig(config.data.config);
            }
        };

        const handleLogin = async() => {
            const login = await axios({
                url: '/api/v1/user/redirectSignIn',
                method: 'GET',
                params: {
                    token
                }
            });
            console.log("login", login);

            if (login.data.code === 0) {
                setWithExpiry('uuid', login.data.data.uuid, 1800000);
                setWithExpiry('isChina', login.data.data.country, 1800000);

                siteLanguage();
            }

            getData();
        };

        const getData = async() => {
            getAccountInfo();
        };

        const token = getParamString('token', window.location.href);

        if (Object.keys(config).length) {
            if (token && !checkLogin()) {
                localStorage.clear();
                handleLogin();
            } else {
                getData();
            }
        } else {
            getConfig();
        }
    }, [config]);

    useEffect(() => {
        siteLanguage();
        disableDevtool();
        triggerPopup("redirection");
    }, []);

    const siteLanguage = async() => {
        const url_lang = /^.{8}[^]*\/([^]*)/.exec(window.location.href)[1];

        const getLanguage = async() => {
            if (!!getWithExpiry('uuid')) {
                let lang;

                switch (getWithExpiry('isChina')) {
                    case "1":
                        lang = 'sc';
                        break;
                    case "71":
                    case "81":
                    case "82":
                        lang = 'tc';
                        break;
                    case "THA":
                        lang = 'th';
                        break;
                    case "VNM":
                        lang = 'vn';
                        break;
                    default:
                        lang = 'en';
                        break;
                }

                changeLanguage(lang, true);
                fireLivechat(lang);
            } else {
                const lang = await axios({
                    url: '/api/v1/location/',
                    method: 'GET'
                });

                if (lang.data.code === 0) {
                    changeLanguage(lang.data.data.lang);
                    fireLivechat(lang.data.data.lang);
                }
            }
        };

        if (['en', 'sc', 'tc', 'kr', 'vn', 'th'].includes(url_lang)) {
            changeLanguage(url_lang);
            fireLivechat(url_lang);
        } else {
            getLanguage();
        }
    };

    const getAccountInfo = async() => {
        if (getWithExpiry('uuid')) {
            const account = await axios({
                url: '/api/v1/crm/redEnvelopes/accountInfo',
                method: 'GET',
                params: {
                    uuid: getWithExpiry('uuid')
                }
            });
            console.log("account", account);

            if (account.data.code === 0) {
                setAccountInfo(account.data.data);
            }
        }
    };

    const changeLanguage = (lang, login = false) => {
        const url_lang = /^.{8}[^]*\/([^]*)/.exec(window.location.href)[1];

        i18n.changeLanguage(lang);
        if(['en', 'sc', 'tc', 'vn', 'th'].includes(url_lang) && (url_lang !== lang)) {
            window.location.href = `${window.location.origin}/7years/${lang}`;
        }

        document.title = t('title');
        document.getElementsByTagName('meta')["description"].content = t('desc');
    };

    const fireLivechat = (lang) => {
        let group = "";

        switch (lang) {
            case "sc":
            case "tc":
                group = "1";
                break;
            case "kr":
                group = "2";
                break;
            case "en":
            case "th":
                group = "3";
                break;
            case "vn":
                group = "4";
                break;
        }

        postscribe('#layout', '<script type=\'text/javascript\'>\n' +
            'window.__lc = window.__lc || {};\n' +
            'window.__lc.license = 10956587\n' +
            'window.__lc.group = ' + group + ';\n' +
            '(function() {\n' +
            'var lc = document.createElement(\'script\'); lc.type = \'text/javascript\'; lc.async = true;\n' +
            'lc.src = (\'https:\' == document.location.protocol ? \'https://\' : \'http://\')+\'cdn.livechatinc.com/tracking.js\';\n' +
            'var s = document.getElementsByTagName(\'script\')[0]; s.parentNode.insertBefore(lc, s);\n' +
            '})();\n' +
            '</script>');
    }

    i18n.on('languageChanged', function(lng) {
        setLanguage(lng);
    });

    const checkLogin = () => {
        return !!getWithExpiry('uuid');
    };

    const setWithExpiry = (key, value, ttl) => {
        const now = new Date();

        const item = {
            value: value,
            expiry: now.getTime() + ttl
        }
        localStorage.setItem(key, JSON.stringify(item));
    };

    const getWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    };

    const togglePopup = () => {
        setPopup(!popup);
    };

    const triggerPopup = (type, content = null) => {
        if (content) {
            setPopupContent(content);
        }
        setPopup(true);
        setPopupType(type);
    };

    return (
        <div className="layout" id="layout">
            <Navbar
                uuid={getWithExpiry('uuid')}
                config={config}
                defaultLanguage={language}
                changeLanguage={changeLanguage.bind(this)} />
            <Header
                language={language}
                uuid={getWithExpiry('uuid')}
                triggerPopup={triggerPopup.bind(this)} />
            <Content
                uuid={getWithExpiry('uuid')}
                isChina={getWithExpiry('isChina') === "1"}
                config={config}
                accountInfo={accountInfo}
                language={language}
                triggerPopup={triggerPopup.bind(this)} />
            {
                popup &&
                    <PopUp
                        uuid={getWithExpiry('uuid')}
                        language={language}
                        type={popupType}
                        content={popupContent}
                        config={config}
                        accountInfo={accountInfo}
                        close={togglePopup.bind(this)}
                        checkRemainingPackets={getAccountInfo.bind(this)}
                        triggerPopup={triggerPopup.bind(this)} />
            }
            <Footer language={language} />
        </div>
    )
}

export default withRouter(Layout);
