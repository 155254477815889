import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './scss/rewards.scss';

const Rewards = (props) => {
    const { uuid, config, language, accountInfo, triggerPopup } = props;
    const { t } = useTranslation();

    const [cardHeight, setCardHeight] = useState(665);
    const [titleHeight, setTitleHeight] = useState(25);
    const [classicButtonText, setClassicButtonText] = useState(t('rewards.get'));
    const [exclusiveButtonText, setExclusiveButtonText] = useState(t('rewards.get'));
    const [welcomeButtonText, setWelcomeButtonText] = useState(t('rewards.get'));
    const [classicButtonAction, setClassicButtonAction] = useState('');
    const [exclusiveButtonAction, setExclusiveButtonAction] = useState('');
    const [welcomeButtonAction, setWelcomeButtonAction] = useState('');
    const [slide, setSlide] = useState(false);
    const [slideSecond, setSlideSecond] = useState(false);
    const [slideThird, setSlideThird] = useState(false);
    const [containerHeight, setContainerHeight] = useState();
    const [imageHeight, setImageHeight] = useState(220);

    const rewardsTitleRef = useRef(null);
    const classicTitleRef = useRef(null);
    const specialTitleRef = useRef(null);
    const welcomeTitleRef = useRef(null);
    const classicContentRef = useRef(null);
    const specialContentRef = useRef(null);
    const welcomeContentRef = useRef(null);
    const classicButtonRef = useRef(null);
    const specialButtonRef = useRef(null);
    const welcomeButtonRef = useRef(null);

    useEffect(() => {
        hidePacketSlide();
        updateContainerSize();
    }, []);

    useEffect(() => {
        if (uuid && accountInfo) {
            if (accountInfo.commonNotReceivingAmount === 0) {
                setClassicButtonText(t('rewards.deposit'));
                setClassicButtonAction('crm-home');
            } else {
                setClassicButtonText(t('rewards.get'));
                setClassicButtonAction('redeem');
            }

            if (accountInfo.specialNotReceivingAmount === 0) {
                setExclusiveButtonText(t('rewards.deposit'));
                setExclusiveButtonAction('crm-home');
            } else {
                setExclusiveButtonText(t('rewards.get'));
                setExclusiveButtonAction('redeem');
            }

            if (accountInfo.newNotReceivingAmount === 0) {
                setWelcomeButtonText(t('rewards.deposit'));
                setWelcomeButtonAction('crm-home');
            } else {
                setWelcomeButtonText(t('rewards.get'));
                setWelcomeButtonAction('redeem');
            }
        } else {
            setClassicButtonText(t('rewards.get'));
            setExclusiveButtonText(t('rewards.get'));
            setWelcomeButtonText(t('rewards.get'));
            setClassicButtonAction('crm-login');
            setExclusiveButtonAction('crm-login');
            setWelcomeButtonAction('crm-login');
        }
    }, [uuid, accountInfo]);

    useEffect(() => {
        if (slide) {
            setTimeout(() => {
                setSlideSecond(true);

                setTimeout(() => {
                    setSlideThird(true);
                }, 500);
            }, 500);
        }
    }, [slide]);

    useLayoutEffect(() => {
        const updateSize = () => {
            hidePacketSlide();
            updateContainerSize();
        }

        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useLayoutEffect(() => {
        const titlePosition = rewardsTitleRef.current.offsetTop - 200;

        const detectScroll = () => {
            if (window.pageYOffset >= titlePosition) {
                setSlide(true);
            }
        }

        window.addEventListener('scroll', detectScroll);
        return () => window.removeEventListener('scroll', detectScroll);
    }, []);

    const updateContainerSize = () => {
        let image = window.innerWidth > 1920 ? 400 : (window.innerWidth > 1550 ? 302 : 220);
        const titleMaxHeight = Math.max(classicTitleRef.current.clientHeight, specialTitleRef.current.clientHeight, welcomeTitleRef.current.clientHeight);
        const contentMaxHeight = Math.max(classicContentRef.current.clientHeight, specialContentRef.current.clientHeight, welcomeContentRef.current.clientHeight);
        const buttonMaxHeight = Math.max(classicButtonRef.current.clientHeight, specialButtonRef.current.clientHeight, welcomeButtonRef.current.clientHeight);
        const contentHeight = contentMaxHeight + buttonMaxHeight + 35;
        const cardHeight = titleMaxHeight + image + contentHeight + 86;

        setTitleHeight(titleMaxHeight);
        setImageHeight(image);
        setContainerHeight(contentHeight);
        setCardHeight(cardHeight);
    };

    const hidePacketSlide = () => {
        setSlide(false);
        setSlideSecond(false);
        setSlideThird(false);
    };

    const redirectRedemption = (type, action) => {
        switch(action) {
            case 'crm-login':
            case 'crm-home':
                window.location.href = config.crm_client;
                break;
            case 'redeem':
                openPopup('redeem', {
                    action: 'manual',
                    event: type
                });
                break;
        }
    };

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    return (
        <div className={"rewards " + language} id="user-records">
            <div className="rewards-container">
                <div className="title" ref={rewardsTitleRef}>{t('rewards.title')}</div>
                <div className="desc" dangerouslySetInnerHTML={{__html: t('rewards.desc')}}/>
                {
                    uuid &&
                        <div className="user-records">
                            <div className="record-card">
                                <div className="record-card-wrapper">
                                    <div className="header">
                                        <div className="record-title">{t('rewards.record.title')}</div>
                                        <span className="record-desc">{t('rewards.record.desc')}</span>
                                    </div>
                                    <div className="record-container">
                                        <div>
                                            {t('rewards.record.packets')}
                                            <span>{accountInfo ? accountInfo.receivedAmount : 0} {t('rewards.record.item')}</span>
                                        </div>
                                        <div>
                                            {t('rewards.record.pending')}
                                            <span>{accountInfo ? accountInfo.notReceivingAmount : 0} {t('rewards.record.item')}</span>
                                        </div>
                                        <div>
                                            {t('rewards.record.amount')}
                                            <span>{accountInfo ? accountInfo.totalBonus : 0} USD</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="record-card">
                                <div className="record-card-wrapper">
                                    <div className="header">
                                        <div className="record-title">{t('rewards.record.transaction.title')}</div>
                                    </div>
                                    <div className="record-container">
                                        <div>
                                            {t('rewards.record.transaction.deposit')}
                                            <span>{accountInfo ? accountInfo.totalDeposit : 0} USD</span>
                                        </div>
                                        <div>
                                            {t('rewards.record.transaction.amount')}
                                            <span>{accountInfo ? accountInfo.totalCommonSymbolLots : 0} {t('rewards.record.lot')}</span>
                                        </div>
                                        <div>
                                            {t('rewards.record.transaction.lots')}
                                            <span>{accountInfo ? accountInfo.totalSpecialSymbolLots : 0} {t('rewards.record.lot')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <div className="card-container desktop">
                    <div className="card" style={{height: cardHeight + 'px'}}>
                        <div className="title"
                             ref={classicTitleRef}
                             style={{height: titleHeight + 'px'}}>{t('rewards.classic.title')}</div>
                        <div className="slider-container" style={{height: imageHeight + 'px'}}>
                            <div className={slide ? "slider slide" : "slider"} style={{maxHeight: slide ? imageHeight + 'px' : '0px'}}>
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/rewards-packet.png" alt="Packets" />
                                <span>{t('rewards.packets.title')}</span>
                            </div>
                        </div>
                        <div className="desc-container" style={{height: containerHeight + 'px'}}>
                            <div className="desc" ref={classicContentRef}>
                                <div dangerouslySetInnerHTML={{__html: t('rewards.classic.desc')}} />
                            </div>
                            <div className="button-wrapper" ref={classicButtonRef}>
                                {
                                    uuid &&
                                        <div className="availability">{t('rewards.availability') + (accountInfo ? accountInfo.commonNotReceivingAmount : 0)}</div>
                                }
                                <div className={accountInfo.commonNotReceivingAmount === 0 ? "button-get deposit" : "button-get redeem"} onClick={() => redirectRedemption('classic', classicButtonAction)}>{classicButtonText}</div>
                            </div>
                        </div>
                    </div>
                    <div className="card" style={{height: cardHeight + 'px'}}>
                        <div className="title"
                             ref={specialTitleRef}
                             style={{height: titleHeight + 'px'}}>{t('rewards.special.title')}</div>
                        <div className="slider-container" style={{height: imageHeight + 'px'}}>
                            <div className={slideSecond ? "slider slide" : "slider"} style={{maxHeight: slideSecond ? imageHeight + 'px' : '0px'}}>
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/rewards-packet.png" alt="Packets" />
                                <span>{t('rewards.packets.title')}</span>
                            </div>
                        </div>
                        <div className="desc-container" style={{height: containerHeight + 'px'}}>
                            <div className="desc" ref={specialContentRef}>
                                <div dangerouslySetInnerHTML={{__html: t('rewards.special.desc')}} />
                            </div>
                            <div className="button-wrapper" ref={specialButtonRef}>
                                {
                                    uuid &&
                                        <div className="availability">{t('rewards.availability') + (accountInfo ? accountInfo.specialNotReceivingAmount : 0)}</div>
                                }
                                <div className={accountInfo.specialNotReceivingAmount === 0 ? "button-get deposit" : "button-get redeem"} onClick={() => redirectRedemption('exclusive', exclusiveButtonAction)}>{exclusiveButtonText}</div>
                            </div>
                        </div>
                    </div>
                    <div className="card" style={{height: cardHeight + 'px'}}>
                        <div className="title"
                             ref={welcomeTitleRef}
                             style={{height: titleHeight + 'px'}}>{t('rewards.welcome.title')}</div>
                        <div className="slider-container" style={{height: imageHeight + 'px'}}>
                            <div className={slideThird ? "slider slide" : "slider"} style={{maxHeight: slideThird ? imageHeight + 'px' : '0px'}}>
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/rewards-packet.png" alt="Packets" />
                                <span>{t('rewards.packets.title')}</span>
                            </div>
                        </div>
                        <div className="desc-container" style={{height: containerHeight + 'px'}}>
                            <div className="desc" ref={welcomeContentRef}>
                                <div dangerouslySetInnerHTML={{__html: t('rewards.welcome.desc')}} />
                            </div>
                            <div className="button-wrapper" ref={welcomeButtonRef}>
                                {
                                    uuid &&
                                        <div className="availability">{t('rewards.availability') + (accountInfo ? accountInfo.newNotReceivingAmount : 0)}</div>
                                }
                                <div className={accountInfo.newNotReceivingAmount === 0 ? "button-get deposit" : "button-get redeem"} onClick={() => redirectRedemption('welcome', welcomeButtonAction)}>{welcomeButtonText}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-container mobile">
                    <div className="card">
                        <div className="card-wrapper">
                            <div className="image">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/single-packet.png" alt="Packets"/>
                            </div>
                            <div className="text">
                                <div className="title">{t('rewards.classic.title')}</div>
                                <div className="desc" dangerouslySetInnerHTML={{__html: t('rewards.classic.desc')}} />
                                {
                                    uuid &&
                                    <div className="availability">{t('rewards.availability') + (accountInfo ? accountInfo.commonNotReceivingAmount : 0)}</div>
                                }
                            </div>
                        </div>
                        <div className={accountInfo.commonNotReceivingAmount === 0 ? "button-get deposit" : "button-get redeem"} onClick={() => redirectRedemption('classic', classicButtonAction)}>{classicButtonText}</div>
                    </div>
                    <div className="card">
                        <div className="card-wrapper">
                            <div className="image">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/single-packet.png" alt="Packets"/>
                            </div>
                            <div className="text">
                                <div className="title">{t('rewards.special.title')}</div>
                                <div className="desc" dangerouslySetInnerHTML={{__html: t('rewards.special.desc')}} />
                                {
                                    uuid &&
                                    <div className="availability">{t('rewards.availability') + (accountInfo ? accountInfo.specialNotReceivingAmount : 0)}</div>
                                }
                            </div>
                        </div>
                        <div className={accountInfo.specialNotReceivingAmount === 0 ? "button-get deposit" : "button-get redeem"} onClick={() => redirectRedemption('exclusive', exclusiveButtonAction)}>{exclusiveButtonText}</div>
                    </div>
                    <div className="card">
                        <div className="card-wrapper">
                            <div className="image">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/single-packet.png" alt="Packets"/>
                            </div>
                            <div className="text">
                                <div className="title">{t('rewards.welcome.title')}</div>
                                <div className="desc" dangerouslySetInnerHTML={{__html: t('rewards.welcome.desc')}} />
                                {
                                    uuid &&
                                    <div className="availability">{t('rewards.availability') + (accountInfo ? accountInfo.newNotReceivingAmount : 0)}</div>
                                }
                            </div>
                        </div>
                        <div className={accountInfo.newNotReceivingAmount === 0 ? "button-get deposit" : "button-get redeem"} onClick={() => redirectRedemption('welcome', welcomeButtonAction)}>{welcomeButtonText}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rewards;
