import React, { useState, useEffect } from 'react';
import scroll, { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import './scss/navbar.scss';

import Dropdown from '../common/dropdown';

const customDropdown = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' : 'grey',
        padding: 20,
    }),
    control: () => ({
        width: 150,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
};

const Navbar = (props) => {
    const { uuid, config, changeLanguage, defaultLanguage } = props;
    const [scrollPosition, setScrollPosition] = useState(null);

    const { t } = useTranslation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'sc', label: '简' },
        { value: 'tc', label: '繁' },
        { value: 'vn', label: 'Việt' },
        { value: 'th', label: 'ไทย' }
    ];

    const login = [
        { value: 'record', label: t('navigation.record') },
        { value: 'logout', label: t('navigation.logout') },
    ];

    useEffect(() => {
        window.addEventListener('scroll', updateScroll);
    },[scrollPosition]);

    const updateScroll = () => {
        setScrollPosition(window.scrollY);
    };

    const getSelectedLanguage = languages.find(obj => obj.value === defaultLanguage);

    const  changeSelectedLanguage = (selected) => {
        changeLanguage(selected.value);
        window.location.href = window.location.origin + "/7years/" + selected.value;
    };

    const changeSelectedOption = (selected) => {
        switch (selected.value) {
            case "record":
                window.location.href = config.crm_client;
                break;
            case "logout":
                localStorage.clear();
                window.location.reload(false);
                break;
        }
    };

    const redirectLogin = () => {
        if (uuid) {
            scroll.scroller.scrollTo('user-records', {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -120
            });
        } else {
            window.location.href = config.crm_client;
        }
    };

    return (
        <>
            <div className="navigation desktop">
                <div className={scrollPosition > 75 ? "navigation-container colour-change" : "navigation-container"}>
                    <div className="logo">
                        <a href="https://zh.dooprimeio.com/" target="_blank">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/logo.png" alt="Doo Prime Logo"/>
                        </a>
                    </div>
                    <div className="menu">
                        <div><Link to="terms" spy={true} smooth={true} offset={-120}>{t('navigation.terms')}</Link></div>
                        <div className="login-button" onClick={() => redirectLogin()}>
                            <span>{ uuid ? t('navigation.packets') : t('navigation.login.desktop')}</span>
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/single-packet-144.png" alt="Packet"/>
                        </div>
                        {
                            uuid &&
                                <div className="login-select">
                                    <Dropdown className="login-dropdown wow fadeInUp" options={login} styles={customDropdown} placeholder={uuid} changeSelected={changeSelectedOption} value={''} />
                                </div>
                        }
                        <div className="language-select">
                            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/7th-anniversary/desktop/icon-common-language.png" alt="Language"/>
                            <Dropdown className="language-dropdown wow fadeInUp" options={languages} styles={customDropdown} placeholder="" changeSelected={changeSelectedLanguage} value={getSelectedLanguage} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="navigation mobile">
                <div className={scrollPosition > 80 ? "navigation-container colour-change" : "navigation-container"}>
                    <div className="container-left">
                        <div className="logo wow fadeInUp">
                            <a href="https://zh.dooprimeio.com/" target="_blank">
                                <img alt="Doo Prime Logo"/>
                            </a>
                        </div>
                    </div>
                    <div className="container-right">
                        <div className="login">
                            {
                                !uuid &&
                                    <div className="open-account" onClick={() => redirectLogin()}>{t('navigation.login.mobile')}</div>
                            }
                            {
                                uuid &&
                                    <div className="login-select">
                                        <Dropdown className="login-dropdown wow fadeInUp" options={login} styles={customDropdown} placeholder={uuid} changeSelected={changeSelectedOption} value={''} />
                                    </div>
                            }
                            <div className="language-select">
                                <Dropdown className="language-dropdown wow fadeInUp" options={languages} styles={customDropdown} placeholder="" changeSelected={changeSelectedLanguage} value={getSelectedLanguage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar;
