import React from 'react';

import './scss/index.scss';

import CrmRegister from './crm-register';
import Info from './info';
import Redeem from './redeem';
import RedeemSuccess from './redeem-success';
import RedeemFail from './redeem-fail';
import Redirection from './redirection';

const PopUp = (props) => {
    const { uuid, language, type, content, config, accountInfo, close, triggerPopup, checkRemainingPackets } = props;

    const openPopup = (type, content = null) => {
        triggerPopup(type, content);
    };

    const checkPackets = () => {
        checkRemainingPackets();
    };

    return (
        <div>
            {
                type === 'crm-register' &&
                    <CrmRegister
                        language={language}
                        config={config}
                        close={close}/>
            }
            {
                type === 'info' &&
                    <Info
                        content={content}
                        close={close} />
            }
            {
                type === 'redeem' &&
                    <Redeem
                        uuid={uuid}
                        config={config}
                        content={content}
                        close={close}
                        language={language}
                        triggerPopup={openPopup.bind(this)} />
            }
            {
                type === 'redeem-success' &&
                    <RedeemSuccess
                        content={content}
                        config={config}
                        accountInfo={accountInfo}
                        close={close}
                        language={language}
                        triggerPopup={openPopup.bind(this)}
                        checkPackets={checkPackets.bind(this)}/>
            }
            {
                type === 'redeem-fail' &&
                    <RedeemFail
                        close={close}
                        content={content} />
            }
            {
                type === 'redirection' &&
                    <Redirection
                        close={close} />
            }
        </div>
    )
}

export default PopUp;
