import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from "../src/locales/en.json";
import translationSc from "../src/locales/sc.json";
import translationTc from "../src/locales/tc.json";
import translationVn from "../src/locales/vn.json";
import translationTh from "../src/locales/th.json";

const lngDetector = new LanguageDetector();
lngDetector.addDetector({
    name: 'customDetector',
    lng: window.location.href.split("/"),
});

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEn
            },
            sc: {
                translation: translationSc
            },
            tc: {
                translation: translationTc
            },
            vn: {
                translation: translationVn
            },
            th: {
                translation: translationTh
            }
        },
        fallbackLng: "en",
        debug: false,
        detection: {
            order: [ 'path', 'navigator' ],
            lookupFromPathIndex: 1,
        },
        supportedLngs: [ 'en', 'sc', 'tc', 'vn', 'th' ],
        interpolation: {
            escapeValue: false
        }
});

export default i18n;
